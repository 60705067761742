<template>
  <footer class="bg-gray-50">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-10 lg:px-8">
      <div class="flex justify-center">
        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div class="flex items-center">
            <a href="https://x.com/monkeyrush_bot" target="_blank" class="ui-social-link mr-3 p-3 rounded-xl">
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20">
              <path class="fill-white" fill="currentColor" d="M15.8.9h3.1l-6.7 7.7L20 19.1h-6.2L9 12.7 3.5 19H.4l7.2-8.2L0 .9h6.3l4.4 5.8L15.8.9zm-1.1 16.3h1.7L5.4 2.7H3.6l11.1 14.5z"></path>
            </svg>
            </a>

            <a href="https://t.me/monkeyrush" target="_blank" class="ui-social-link mr-3 p-3 rounded-xl">
              <svg class="w-6" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-white" d="M27.6132 4.873C27.6132 4.873 30.2032 3.863 29.9866 6.31567C29.9152 7.32567 29.2679 10.861 28.7639 14.6843L27.0372 26.011C27.0372 26.011 26.8932 27.6703 25.5979 27.959C24.3032 28.247 22.3606 26.949 22.0006 26.6603C21.7126 26.4437 16.6046 23.197 14.8059 21.6103C14.3019 21.177 13.7259 20.3117 14.8779 19.3017L22.4319 12.087C23.2952 11.2217 24.1586 9.20167 20.5612 11.6543L10.4879 18.5077C10.4879 18.5077 9.33655 19.2297 7.17855 18.5803L2.50122 17.137C2.50122 17.137 0.774553 16.055 3.72455 14.973C10.9199 11.5823 19.7699 8.11967 27.6119 4.873H27.6132Z" fill="currentColor"></path>
              </svg>
            </a>

            <a href="https://www.youtube.com/@monkeyrush_bot" target="_blank" class="ui-social-link mr-3 p-3 rounded-xl">
              <svg class="w-6 stroke-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
              </svg>
            </a>
          </div>
        </nav>
      </div>
      <p class="mt-5 text-center text-xs leading-5 text-gray-500">2024 MonkeyRush, Just start playing now.</p>
    </div>
  </footer>
</template>

<script setup>
</script>