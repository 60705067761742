<template>
  <div id="roadmap" class="p-5 pl-10 lg:p-24 bg-gray-50">
    <h2 class="mt-10 lg:mt-0 text-center text-4xl lg:text-5xl font-bold mb-12">
      MonkeyRush Roadmap
    </h2>

    <div class="max-w-4xl mx-auto">
      <div class="relative border-l-4 border-yellow-400 border-dashed">
        <!-- Stage 1 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-8">
            <img src="/img/menu-icon-home.png" class="w-16">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold">July 22, 2024</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 text-green-600 mr-2 flex-shrink-0" /> Game Launch
                </li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 text-green-600 mr-2 flex-shrink-0" /> Referral System
                </li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 text-green-600 mr-2 flex-shrink-0" /> Daily Rewards
                </li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-green-600 mr-2 flex-shrink-0" /> Gameplay Mechanics Update
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 2 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-8">
            <img src="/img/squad-icon.png" class="w-16">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold">July 25, 2024</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex items-center">
                  <CheckCircleIcon class="w-5 text-green-600 mr-2 flex-shrink-0" />Squads</li>
                <li class="flex items-center">
                  <CheckCircleIcon class="w-5 text-green-600 mr-2 flex-shrink-0" />Earn Tasks</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 3 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">In progress..</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />MonkeyRush Wallet</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Partner Earn Tasks</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Exchange MonkeyRush Tokens for Notcoin or TON</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 4 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">Soon...</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Partner Collaborations</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Investments</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 5 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">Is planned...</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Negotiations with Exchanges</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />On-chain Infrastructure Development</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 6 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">Is planned...</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Monkey Wallet 🤩 on the TON Network</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />MonkeyRush Tokens Staking</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 7 -->
        <div class="mb-6 ml-10 lg:mb-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">Is planned...</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />AirDrop</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Additional Investments</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stage 8 -->
        <div class="ml-10 lg:ml-16">
          <div class="absolute mt-1.5 -left-7">
            <img src="/img/clock-icon.png" class="w-12">
          </div>
          <div class="p-3 lg:p-6 bg-white rounded-xl shadow-md flex lg:flex-row flex-col">
            <div class="lg:w-1/3 lg:mb-0 mb-2 lg:mb-4 text-xl lg:text-4xl font-bold text-gray-400">Is planned...</div>
            <div class="lg:w-2/3 lg:ml-6 lg:pl-5">
              <ul>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Web 3 Pre-listing Quest</li>
                <li class="flex">
                  <CheckCircleIcon class="w-5 h-5 text-gray-400 mr-2 flex-shrink-0" />Token Listing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {CheckCircleIcon} from "@heroicons/vue/24/solid";

export default {
  components: {
    CheckCircleIcon
  }
}
</script>
