<template>
  <div class="bg-white p-6">
    <div class="max-w-4xl mx-auto">
      <h1 class="text-2xl font-bold text-gray-800 mb-4">Privacy Policy for MonkeyRush</h1>
      <p class="text-sm text-gray-600 mb-4">Last Updated: 2024-08-15</p>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">1. Data Collection</h2>
      <p class="text-gray-600 mb-2">
        We collect and store the following types of data:
      </p>
      <ul class="list-disc list-inside text-gray-600 mb-4">
        <li>Activity Information: We log user actions in the game, including clicks, virtual monkey purchases, and task completion.</li>
        <li>Payment Information: When exchanging in-game currency for cryptocurrencies (Toncoin, Notcoin), we may collect and process the user's crypto wallet data.</li>
        <li>Partner Link Information: When users complete tasks, such as subscribing to Telegram groups or following partner links, information about these actions may be shared with third parties (partners).</li>
      </ul>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">2. Data Usage</h2>
      <p class="text-gray-600 mb-4">
        The collected data is used for:
      </p>
      <ul class="list-disc list-inside text-gray-600 mb-4">
        <li>Improving the gaming experience.</li>
        <li>Ensuring the completion and tracking of tasks.</li>
        <li>Maintaining and enhancing the in-game currency exchange functionality for cryptocurrencies.</li>
        <li>Analyzing and optimizing partner programs.</li>
      </ul>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">3. Data Sharing</h2>
      <p class="text-gray-600 mb-4">
        We do not share users' personal data with third parties, except where necessary to execute partner programs or currency exchanges. In such cases, we ensure that these third parties comply with data confidentiality and security standards.
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">4. Cryptocurrencies</h2>
      <p class="text-gray-600 mb-4">
        When exchanging in-game currency for cryptocurrencies, users agree to the transfer of data related to their crypto wallets. We do not store this data after transactions are completed and are not responsible for changes in cryptocurrency exchange rates.
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">5. Data Protection</h2>
      <p class="text-gray-600 mb-4">
        We take measures to protect user data, including using encryption and other security technologies. However, no security system is entirely foolproof, and we cannot guarantee absolute protection of data.
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">6. Policy Changes</h2>
      <p class="text-gray-600 mb-4">
        We may update this Privacy Policy from time to time. In the event of changes, we will notify users through the game or other channels.
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mb-2">7. Contact Information</h2>
      <p class="text-gray-600">
        If you have any questions or comments regarding our Privacy Policy, please contact us at:
        <a href="https://t.me/monkeyrush_support" class="underline decoration-solid">MonkeyRush Support</a>.
      </p>
    </div>
  </div>
  <MainContact />
</template>

<script>
import MainContact from "@/components/main/MainContact.vue";
export default {
  name: 'PrivacyPolicy',
  components: {
    MainContact
  }
}
</script>
