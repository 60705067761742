<template>
  <MainHome />
  <MainRoadmap />
  <div class="bg-gray-50 px-4 pb-10">
    <div class="rounded-3xl px-2 w-full max-w-4xl mx-auto flex flex-col text-center text-white items-center justify-center bg-gradient-to-r from-gray-800 via-gray-900 to-[#191b24] py-10">
      <h1 class="text-3xl mb-3">MonkeyRush is more than just a game!</h1>
      <p class="opacity-90">Become the leader of a monkey tribe and guide it through the stages of evolution. Earn Monkey Coins and complete quests. Monkey Coins can be exchanged for real cryptocurrency.</p>
      <a href="https://t.me/monkeyrush_bot/app" target="_blank" class="mt-6 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:from-yellow-500 hover:to-yellow-400 focus:outline-none focus:ring-4 focus:ring-yellow-300">
        Play and earn
      </a>
    </div>
  </div>
  <MainContact />
</template>

<script>
import MainHome from '../components/main/MainHome.vue'
import MainRoadmap from '../components/main/MainRoadmap.vue'
import MainContact from '../components/main/MainContact.vue'

export default {
  components: {
    MainHome,
    MainRoadmap,
    MainContact,
  },
}
</script>